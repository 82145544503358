import React from "react"
import PhotoGallery from "../../components/photogallery/PhotoGallery"
import Layout from "../../components/layout/Layout"
import { getImageNodes } from "../../lib"
import { graphql } from "gatsby"
import { StoryHeader } from "../../components/storyheader/StoryHeader"

export default function Musahar({ data }) {
  const IMAGES = getImageNodes(data)
  const [COVER_IMAGE] = IMAGES
  return (
    <Layout title="Musahar" heading="Musahar">
      <StoryHeader storyImage={COVER_IMAGE}>
        <>
          <p>
            In 2016 under a survey team of international polling agency Cvoter,
            documented mushar community living mostly in Patna and Muzzafarpur
            districts in the state of Bihar, India.
          </p>
          <p>
            The Musahars are considered one of the lowest of the Dalit groups
            within India and suffer tremendously from their status in society.
            Their name is derived from two words meaning "rat catcher" and is
            likely attributed to them for their tendency to eat rodents in times
            of dire need.
          </p>
        </>
      </StoryHeader>
      <PhotoGallery photos={IMAGES} />
    </Layout>
  )
}

export const query = graphql`
  query Musahar {
    allFile(filter: { relativeDirectory: { eq: "musahar" } }) {
      edges {
        node {
          name
          base
          publicURL
        }
      }
    }
  }
`
